
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { AxiosError } from "axios";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Field, Form } from "vee-validate";
import moment from "moment-timezone";

export default defineComponent({
  name: "config-settings",
  data() {
    return {
      email: "",
      default: ""
    };
  },
  components: {
    Field,
    Form
  },
  computed: {
    userData() {
      const store = useStore();
      const user = store.getters.currentUser;

      return JSON.stringify({
        firstName: user.firstName,
        lastName: user.lastName,
        contactNumber: user.contactNumber || ""
      });
    }
  },
  methods: {
    async inviteAdmin() {
      if (this.email === "") return false;
      const checkEmail = data => ApiService.post("/v1/user/email-exist", data);

      const submitButton = document.querySelector(
        "#adminInviteButton"
      ) as HTMLElement;

      try {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "disabled");

        const [emailName, emailDomain] = this.email.split("@");
        if (emailDomain !== "foundme.ai")
          throw new Error("Foundme.ai email only!");

        const emailCheck = await checkEmail({ email: this.email });

        if (emailCheck && emailCheck.data && emailCheck.data.success) {
          throw new Error(`Sorry, ${this.email} is already a user!`);
        }

        const invite = data => ApiService.post(`/v1/user/admin-invite`, data);
        const { data } = await invite({ email: this.email });

        if (data.success) {
          this.default = this.email;
          this.email = "";
          Swal.fire({
            title: "Admin Invite",
            text: "Invite sent!",
            icon: "success"
          });
        }
      } catch (error) {
        let message;

        if (error instanceof Error) {
          message = error.message;
        } else {
          const err = error as AxiosError;
          message = err.response?.data.message;
        }

        Swal.fire({
          icon: "error",
          text: message || "Unable to update.",
          buttonsStyling: false,
          confirmButtonText: "Try again",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });
      }
      submitButton.removeAttribute("data-kt-indicator");
      submitButton.removeAttribute("disabled");
    }
  },
  async mounted() {
    const store = useStore();
    const user = store.getters.currentUser;
    console.log(user);
    // const startTime = moment("2024-02-26T09:48:40.691+00:00");
    // const endTime = moment();
    // const duration = moment.duration(endTime.diff(startTime));
    // const hours = duration.asHours();
    // console.log({hours})
  }
});
